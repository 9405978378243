<template>
  <b-sidebar
    id="edit-package-sidebar"
    :visible="isEditPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-edit-package-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ $t('package.form.editHeader') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Paket -->
          <validation-provider
            #default="validationContext"
            name="package"
            rules="required"
          >
            <b-form-group :label="$t('package.form.name')" label-for="package">
              <b-form-input
                id="package"
                v-model="packageData.package"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('package.form.namePlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CV -->
          <validation-provider
            #default="validationContext"
            name="cv"
            rules="required|regex:^([0-9]+)$|max:3"
          >
            <b-form-group :label="$t('package.form.cv')" label-for="cv">
              <b-form-input
                id="cv"
                v-model="packageData.cv"
                :state="getValidationState(validationContext)"
                :placeholder="$t('package.form.cvPlaceholder')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Chat -->
          <validation-provider
            #default="validationContext"
            name="chat"
            rules="required|regex:^([0-9]+)$|max:2"
          >
            <b-form-group :label="$t('package.form.chat')" label-for="chat">
              <b-form-input
                id="chat"
                v-model="packageData.chat"
                :state="getValidationState(validationContext)"
                :placeholder="$t('package.form.chatPlaceholder')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required|min:4|max:7"
          >
            <b-form-group :label="$t('package.form.price')" label-for="price">
              <cleave
                id="price"
                v-model="packageData.price"
                class="form-control"
                :class="{
                  'is-invalid':
                    validationContext.errors.length > 0 &&
                    validationContext.touched,
                  'is-valid':
                    validationContext.valid && validationContext.touched,
                }"
                :raw="true"
                :options="options.price"
                :placeholder="$t('package.form.pricePlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <template v-if="isLoading">
                <div class="d-flex align-center">
                  <b-spinner type="grow" small class="mr-1" />
                  Loading...
                </div>
              </template>
              <span v-else>
                {{ $t('common.edit') }}
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, computed } from '@vue/composition-api'
import { required, max, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import { useAsyncState } from '@vueuse/core'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isEditPackageSidebarActive', 'data'],
    event: ['update:is-edit-package-sidebar-active', 'update:data'],
  },
  props: {
    isEditPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      max,
      min,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const blankPackageData = {
      package: null,
      chat: null,
      price: null,
      cv: null,
    }
    const options = {
      price: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
        rawValueTrimPrefix: true,
      },
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetUserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    watch(
      () => props.isEditPackageSidebarActive,
      val => {
        if (val) {
          packageData.value.package = props.data.package
          packageData.value.cv = props.data.cv_quota
          packageData.value.chat = props.data.chat_quota
          packageData.value.price = props.data.price
        }
      },
    )

    // ** Mencegah hit api jika tidak ada perubahan pada inputan
    // ?? Mungkin masih bisa di sederhanakan
    const isEditFormChanged = computed(() => {
      if (!props.data) return false
      if (
        props.data?.package !== packageData.value.package &&
        packageData.value.package !== null
      ) {
        return true
      }
      if (
        props.data?.cv_quota !== packageData.value.cv &&
        packageData.value.cv !== null
      ) {
        return true
      }
      if (
        props.data?.chat_quota !== packageData.value.chat &&
        packageData.value.chat !== null
      ) {
        return true
      }
      if (
        props.data?.price !== Number(packageData.value.price) &&
        packageData.value.price !== ''
      ) {
        return true
      }

      return false
    })

    const { isLoading, execute: onSubmit } = useAsyncState(
      async () => {
        try {
          if (!isEditFormChanged.value) {
            emit('update:is-edit-package-sidebar-active', false)
            return
          }

          const response = await store.dispatch('master-package/editPackage', {
            // eslint-disable-next-line no-underscore-dangle
            id: props.data._id,
            package: packageData.value.package,
            chat_quota: packageData.value.chat,
            price: packageData.value.price,
            cv_quota: packageData.value.cv,
          })

          if (response.data.code === 200) {
            emit('refetch-data')
            emit('update:is-edit-package-sidebar-active', false)

            toast({
              component: ToastificationContent,
              props: {
                title: 'Success creating new package',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        } catch (error) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error creating new package',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            { position: 'top-left' },
          )
        }
      },
      null,
      { immediate: false },
    )

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetUserData,
    )

    return {
      packageData,
      onSubmit,
      isLoading,
      isEditFormChanged,

      refFormObserver,
      getValidationState,
      resetForm,
      options,
    }
  },
}
</script>
