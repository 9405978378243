<template>
  <b-sidebar
    id="add-new-package-sidebar"
    :visible="isAddNewPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-package-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t('package.form.header') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Package Name -->
          <validation-provider
            #default="validationContext"
            name="package"
            rules="required"
          >
            <b-form-group :label="$t('package.form.name')" label-for="package">
              <b-form-input
                id="package"
                v-model="packageData.package"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('package.form.namePlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Quota Chat -->
          <validation-provider
            #default="validationContext"
            name="chat"
            rules="required|regex:^([0-9]+)$|max:3"
          >
            <b-form-group :label="$t('package.form.chat')" label-for="chat">
              <b-form-input
                id="chat"
                v-model="packageData.chat"
                :state="getValidationState(validationContext)"
                :placeholder="$t('package.form.chatPlaceholder')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Quota CV -->
          <validation-provider
            #default="validationContext"
            name="cv"
            rules="required|regex:^([0-9]+)$|max:3"
          >
            <b-form-group :label="$t('package.form.cv')" label-for="cv">
              <b-form-input
                id="cv"
                v-model="packageData.cv"
                :state="getValidationState(validationContext)"
                :placeholder="$t('package.form.cvPlaceholder')"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required|min:4|max:7"
            mode="eager"
          >
            <b-form-group :label="$t('package.form.price')" label-for="price">
              <cleave
                id="price"
                v-model="packageData.price"
                class="form-control"
                :class="{
                  'is-invalid':
                    (validationContext.touched && validationContext.invalid) ||
                    (validationContext.validated && validationContext.invalid),
                  'is-valid': validationContext.valid,
                }"
                :raw="true"
                :options="options.price"
                :placeholder="$t('package.form.pricePlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <template v-if="isLoading">
                <div class="d-flex align-center">
                  <b-spinner small type="grow" class="mr-1" />
                  Loading...
                </div>
              </template>
              <span v-else>{{ $t('common.add') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, max, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { useAsyncState } from '@vueuse/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPackageSidebarActive',
    event: 'update:is-add-new-package-sidebar-active',
  },
  props: {
    isAddNewPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      max,
      min,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const blankPackageData = {
      package: null,
      chat: null,
      price: '',
      quota: null,
    }
    const options = {
      price: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
        rawValueTrimPrefix: true,
      },
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetuserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    const { state, isLoading, execute: onSubmit } = useAsyncState(
      async () => {
        try {
          const response = await store.dispatch('master-package/addPackage', {
            package: packageData.value.package,
            chat_quota: packageData.value.chat,
            price: packageData.value.price,
            cv_quota: packageData.value.quota,
          })

          if (response.data.code === 200) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success creating new package',
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            emit('refetch-data')
            emit('update:is-add-new-package-sidebar-active', false)
          }
        } catch (error) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching Package' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      null,
      { immediate: false, resetOnExecute: false },
    )

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )

    return {
      packageData,
      onSubmit,
      isLoading,
      state,

      refFormObserver,
      getValidationState,
      resetForm,
      options,
    }
  },
}
</script>
