import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPackages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/package', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPackage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/package/create', { ...payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPackage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/package/update/${payload.id}`, {
            package: payload.package, name: payload.name, price: payload.price, valid_until: payload.valid_until, cv_quota: payload.cv_quota,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePackageState(ctx, { id, isActive }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/ethnic/setstatus', { id, is_active: isActive })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePackage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/package/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
