<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <package-list-add-new
          v-if="$can('create', 'Package')"
          :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
          @refetch-data="refetchData"
        />

        <package-list-edit
          v-if="$can('update', 'Package')"
          :is-edit-package-sidebar-active.sync="isEditPackageSidebarActive"
          :data.sync="selectedPackage"
          @refetch-data="refetchData"
        />

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('common.entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="$can('create', 'Package')"
            variant="primary"
            @click="isAddNewPackageSidebarActive = true"
          >
            {{ $t('package.addBtn') }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col>
          <div class="d-flex align-items-right justify-content-end">
            <b-form-input
              v-model="searchQuery"
              debounce="500"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search') + '...'"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refPackageListTable"
      :items="fetchPackages"
      responsive
      :fields="translatedTableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('message.empty')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner variant="primary" label="Loading..." />
        </div>
      </template>

      <template #cell(cv_quota)="data">
        <span>
          {{ toRupiah(data.value) }}
        </span>
      </template>

      <template #cell(valid_until)="data">
        <span class="text-right">
          {{ data.value + ' ' + $t('common.month') }}
        </span>
      </template>

      <template #cell(price)="data">
        <span>
          {{ toRupiah(data.value, 'IDR') }}
        </span>
      </template>

      <template #cell(is_active)="data">
        <b-form-checkbox checked="true" name="check-button" switch />
        <span>
          {{ toRupiah(data.value, 'IDR') }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="$can('update', 'Package')"
            :id="`package-row-${data.item._id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openEditPackageSidebar(data.item)"
          />
          <b-tooltip
            v-if="$can('update', 'Package')"
            :title="$t('package.tooltip')"
            class="cursor-pointer"
            :target="`package-row-${data.item._id}-send-icon`"
            triggers="hover"
            placement="left"
          />

          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="!$can('delete', 'Package')"
              @click="confirmDelete(data.item)"
              @refetch-data="refetchData"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted">{{
            $tc('common.showing', dataMeta.of, {
              first: dataMeta.from,
              last: dataMeta.to,
              total: dataMeta.of,
            })
          }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPackage"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { toRupiah } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import usePackagesList from './usePackageList'
import PackageListAddNew from './PackageListAddNew.vue'
import PackageListEdit from './PackageListEdit.vue'
import packageStoreModule from '../packageStoreModule'
import 'animate.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    PackageListAddNew,
    PackageListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormCheckbox,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`package.tableHeader.${column.key}`),
      }))
    },
  },
  methods: {
    confirmDelete({ _id: id, package: name }) {
      this.$swal({
        title: `${this.$t('message.delete')}${name} ?`,
        text: this.$t('message.caution'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.deleteBtn'),
        cancelButtonText: this.$t('common.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__shakeX',
        },
        buttonsStyling: false,
      }).then(async result => {
        try {
          if (result.value) {
            const res = await store.dispatch('master-package/deletePackage', {
              id,
            })

            if (res.data.code !== 400) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                timer: 2000,
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.refPackageListTable.refresh()
            } else {
              this.$swal({
                icon: 'error',
                title: res.data.code,
                text: 'Something went wrong!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
          }
        } catch (e) {
          this.$swal({
            title: `Error! ${e.response.status}`,
            text: e.response.statusText,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  setup() {
    const PACKAGE_MASTER_STORE_MODULE_NAME = 'master-package'

    // Register module
    if (!store.hasModule(PACKAGE_MASTER_STORE_MODULE_NAME)) {
      store.registerModule(PACKAGE_MASTER_STORE_MODULE_NAME, packageStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_MASTER_STORE_MODULE_NAME)) {
        store.unregisterModule(PACKAGE_MASTER_STORE_MODULE_NAME)
      }
    })

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const isAddNewPackageSidebarActive = ref(false)
    const isEditPackageSidebarActive = ref(false)
    const selectedPackage = ref(null)

    const openEditPackageSidebar = data => {
      selectedPackage.value = data
      isEditPackageSidebarActive.value = true
    }

    const {
      fetchPackages,
      refetchData,
      deletePackage,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refPackageListTable,
      currentPage,
      totalPackage,
      dataMeta,
    } = usePackagesList()

    return {
      fetchPackages,
      refetchData,
      deletePackage,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      isSortDirDesc,
      tableColumns,
      refPackageListTable,
      totalPackage,
      currentPage,
      dataMeta,
      toRupiah,

      openEditPackageSidebar,
      isAddNewPackageSidebarActive,
      isEditPackageSidebarActive,
      selectedPackage,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.package-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
